import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name too short")
    .max(50, "Name too long")
    .required("Name field is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required so we can contact you back."),
  message: Yup.string()
    .required("Message is required!")
    .max(5000, "Message too long"),
  type: Yup.string().optional(),
});

export default validationSchema;
