import { Link } from "gatsby";
import React from "react";
import { LayoutBox } from "../../components/atoms/LayoutBox";
import { ContactForm } from "../../components/forms/ContactForm";
import Layout from "../../components/Layout";
import { TextBanner } from "../../components/molecules/TextBanner";
import { useURLParam } from "../../hooks/useURLParam";

const ContactPageTemplate = () => {
  const title = "Contact us";
  const description =
    "We'd love to hear from you! Please contact us using the form below.";

  const { paramValue: messageFromUrl, isLoading: messageLoading } =
    useURLParam("message");
  const { paramValue: name } = useURLParam("name");
  const { paramValue: email } = useURLParam("email");
  const { paramValue: neumindId } = useURLParam("neumind-id");
  const { paramValue: messageType, isLoading: typeLoading } =
    useURLParam("type");

  const isLoading = messageLoading || typeLoading;

  return (
    <Layout title={title} description={description}>
      <div>
        <TextBanner title={title} />
        <LayoutBox>
          <p>{description}</p>
          <br />
          {!isLoading && (
            <ContactForm
              message={messageFromUrl}
              type={messageType}
              name={name}
              email={email}
              neumindId={neumindId}
            />
          )}
          <br />
          <label className="label has-text-weight-normal pt-3">
            By submitting, you agree that neumind will process your data
            according to our <Link to="/privacy-policy">privacy policy</Link>.
          </label>
        </LayoutBox>
      </div>
    </Layout>
  );
};

export default ContactPageTemplate;
