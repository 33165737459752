import React from "react";
import { Formik, Field } from "formik";
import { navigate } from "gatsby";
import validationSchema from "./validationSchema";

const ContactForm = ({
  message = "",
  type = "",
  name = "",
  email = "",
  neumindId = undefined,
}) => {
  return (
    <Formik
      initialValues={{ name, email, message, type }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        fetch("/.netlify/functions/customer-support", {
          //eslint-disable-line
          method: "POST",
          body: JSON.stringify({
            email: values.email,
            name: values.name,
            message: values.message,
            type: values.type,
            neumindId,
          }),
        })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              navigate("/contact/success");
              setSubmitting(false);
            } else {
              alert(`Error ${response.status}: ${response.statusText}.`); //eslint-disable-line
              setSubmitting(false);
            }
          })
          .catch((error) => {
            console.log(error);
            alert("Error: Please Try Again!"); //eslint-disable-line
            setSubmitting(false);
          });
      }}
      render={({
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleReset,
      }) => (
        <form
          name="contact"
          onSubmit={handleSubmit}
          onReset={handleReset}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <label className="label">
            Name
            <div className="field pt-3">
              <div className="control">
                <Field
                  className="input"
                  type="text"
                  placeholder="Full Name"
                  name="name"
                  id="name"
                />
              </div>

              {touched.name && errors.name && (
                <small className="has-text-danger">{errors.name}</small>
              )}
            </div>
          </label>

          <label className="label">
            Email
            <div className="field pt-3">
              <div className="control">
                <Field
                  className="input"
                  type="email"
                  placeholder="Email"
                  name="email"
                  id="email"
                />
              </div>
              {touched.email && errors.email && (
                <small className="has-text-danger">{errors.email}</small>
              )}
            </div>
          </label>

          {type !== "" && (
            <>
              <label className="label">Query Type</label>
              <p className="pb-3">{type}</p>
            </>
          )}

          {neumindId && (
            <>
              <label className="label">Neumind ID</label>
              <p className="pb-3">{neumindId}</p>
            </>
          )}

          <label className="label">
            Message
            <div className="field pt-3">
              <div className="control">
                <Field
                  className="textarea"
                  component="textarea"
                  placeholder="Message"
                  name="message"
                  id="message"
                  rows="6"
                />
              </div>
              {touched.message && errors.message && (
                <small className="has-text-danger">{errors.message}</small>
              )}
            </div>
          </label>

          <div className="field is-grouped is-pulled-right">
            <div className="control">
              <button className="button" type="reset">
                Clear
              </button>
            </div>
            <div className="control">
              <button
                className="button is-primary"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export { ContactForm };
